<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <!-- md="9" -->
        <Results
          :booking-data="bookingData"
        />
      </b-col>
      <!-- <b-col cols="3">
        <Filters />
        </b-col> -->
    </b-row>
  </div>
</template>

<script>
import {
  BCol, BRow,
} from 'bootstrap-vue'
import {
  ref, onBeforeMount, onUnmounted,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import store from '@/store'

import useReservationDetailBookingHandle from '@reservation/reservation-modify/components/detail/flights-details/useReservationDetailBookingHandle'
import reservationStoreModule from '@reservation/reservationStoreModule'
import modifyFlightStoreModule from '@reservation/reservation-modify/components/detail/flights-details/modifyFlightStoreModule'

// import Filters from './filters.vue'
// import Results from './results.vue'

export default {
  components: {
    BCol,
    BRow,
    // Filters,
    Results: () => import('../../result-search/index.vue'),
  },
  setup() {
    const {
      getAirportGroup,
      fetchAirportGroup,
      searchFlightv2,
      searchFlightForModify,
    } = useReservationDetailBookingHandle()

    const RESERVATION_APP_STORE_MODULE_NAME = 'app-reservation'
    const MODIFY_FLIGHT_APP_STORE_MODULE_NAME = 'app-modify-flight'

    if (!store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) {
      store.registerModule(RESERVATION_APP_STORE_MODULE_NAME, reservationStoreModule)
    }

    if (!store.hasModule(MODIFY_FLIGHT_APP_STORE_MODULE_NAME)) {
      store.registerModule(MODIFY_FLIGHT_APP_STORE_MODULE_NAME, modifyFlightStoreModule)
    }

    onUnmounted(() => {
      // remove result trip
      store.dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/deleteResultSearch`)
      store.dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/setSelectedTrip`, [])

      if (store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(RESERVATION_APP_STORE_MODULE_NAME)
      }

      if (store.hasModule(MODIFY_FLIGHT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(MODIFY_FLIGHT_APP_STORE_MODULE_NAME)
      }
    })

    if (isEmpty(getAirportGroup.value)) { fetchAirportGroup() }

    const bookingData = ref({ ...JSON.parse(localStorage.getItem('bookingData')) })
    // FIXME: check bookingData nếu undefined thì yc load lại booking

    const searchSession = localStorage.getItem('searchSession')
    const dataSession = ref({ ...JSON.parse(searchSession) })

    const searchFlight = localStorage.getItem('search-for-modify')
    const searchData = ref({ ...JSON.parse(searchFlight) })

    onBeforeMount(() => {
      if (dataSession.value.requestType === 'SEARCH_V2') {
        searchFlightv2(searchData.value)
      } else if (dataSession.value.requestType === 'SEARCH_FOR_MODIFY') {
        searchFlightForModify(searchData.value)
      }
    })

    return {
      bookingData,
    }
  },
}
</script>
